import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ornico/services/auth'
import { Router } from '@angular/router';


@Component({
  selector: 'ornico-ui-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  ngOnInit() { }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

}