import { JwtHelper, tokenNotExpired } from 'angular2-jwt'
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Base64 } from 'js-base64';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('token')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Accept': '*/*',
            'Content-Type': 'text/plain; charset=utf-8'
        })
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    login(username, password) {
        return this.http.post<any>('http://fusion.ornico.co.za/Services/Services/Authentication.svc/AuthenticateUser', { username, password }, this.httpOptions)
            .pipe(map(user => {
                sessionStorage.setItem('token', JSON.stringify(user));
                const userDetails = JSON.parse(Base64.atob(JSON.stringify(user)));
                const user_email = userDetails.userID + '@ornicotest.co.za';
                sessionStorage.setItem('userEmail', user_email);
                sessionStorage.setItem('userName', userDetails.name);
                sessionStorage.setItem('userId', userDetails.userID);

                this.getCustomerCode(user_email).subscribe((data) => {
                    sessionStorage.setItem('custCode', JSON.stringify(data).replace(/"/g, ""));
                })
            }));
    }

    getCustomerCode(customer_email: string) {
        return this.http.get(`http://fusion.ornico.co.za/Services/Services/Authentication.svc/AuthenticateViaLink/${customer_email}/false`);
    }

    logout() {
        // remove user from local storage and set current user to null
        sessionStorage.clear();
    }

    isLoggedIn() {
        const token = sessionStorage.getItem('token');
        if (token)
            return true;
        else
            return false;
    }
}
