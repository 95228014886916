import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlipbookUiModule } from '@ornico/flipbook-admin/ui';


const modules = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  FlipbookUiModule
];

@NgModule({
  imports: [...modules],
  exports: [...modules]
})
export class FlipbookCoreModule { }
