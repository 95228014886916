import { Component } from '@angular/core';

@Component({
  selector: 'ornico-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  mediaType: string;
  title = 'Flipbook Admin';
}
