import { RbAction } from '../interfaces/action.interface';
import { actionTypes } from '../enums/action-types.enum';

export namespace actions {
  export const addAction: RbAction = {
    type: actionTypes.add,
    label: 'Add'
  };

  export const editAction: RbAction = {
    type: actionTypes.edit,
    label: 'Edit'
  };

  export const deleteAction: RbAction = {
    type: actionTypes.delete,
    label: 'Delete'
  };

  export const dismissAction: RbAction = {
    type: actionTypes.dismissChanges,
    label: 'Dismiss'
  };

  export const saveAction: RbAction = {
    type: actionTypes.saveChanges,
    label: 'Save'
  };

  export const shareAction: RbAction = {
    type: actionTypes.share,
    label: 'Share'
  };
}