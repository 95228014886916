import { Routes } from '@angular/router';
import { AppLayoutComponent } from '@ornico/flipbook-admin/ui';
import { AuthGuard, LoginComponent } from '@ornico/auth';

const defaultRoute = '/admin/flipbook';

export const children: Routes = [
  {
    path: 'flipbook',
    loadChildren: '@ornico/flipbook-admin/contents#FlipbooksModule'
  },
  {
    path: 'media',
    loadChildren: '@ornico/flipbook-admin/media#FlipbookMediaModule'
  }
];

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: defaultRoute
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        component: AppLayoutComponent,
        children: children,
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: 'login', component: LoginComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];