import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { FlipbookCoreModule } from '@ornico/flipbook-admin/core';

import { AppComponent } from './app.component';
import { routes } from './admin-app.routing';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      routes,
      {
        paramsInheritanceStrategy: 'always',
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
        enableTracing: false
      }),
    BrowserAnimationsModule,
    FlipbookCoreModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }